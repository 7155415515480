import React from 'react'
// website
export const ADMIN_LOGIN = React.lazy(() => import('../../src/container/Components/login'));
export const USERS = React.lazy(() => import('../../src/container/Components/paidUser'));
export const AdminLayout =React.lazy(() => import('../../src/container/AdminLayout/header'));
export const DASHBOARD =React.lazy(() => import('../../src/container/Components/dashboard'));
export const ADD_QUESTIONS =React.lazy(() => import('../../src/container/Components/addQuestion'));
export const ALL_QUESTIONS =React.lazy(() => import('../../src/container/Components/questionslisting'));
export const CHAT =React.lazy(() => import('../../src/container/Components/chat'));
export const ANNOUNCEMENTS =React.lazy(() => import('../../src/container/Components/AnnounceMents/MainTabs'));
export const ADD_SURVEY =React.lazy(() => import('../../src/container/Components/Survey/MainTab'));
export const ADD_NOTIFICATIONS =React.lazy(() => import('../../src/container/Components/Notifications/mainpage'));
export const ALL_TRANSACTION =React.lazy(() => import('../../src/container/Components/allTransactions'));
export const DELETE_USER =React.lazy(() => import('../../src/container/userdecativate'));

