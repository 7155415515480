import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OurRoutes from './Routes';
import * as Routers from './Routers';
// import Loader from '../components/container/Loader/Loader'

function AppRouter() {
    return (
        <Router>
            <Suspense
            // fallback={<Loader />}
            >
                <Routes>
                    {/* website pages */}
                    <Route path={OurRoutes.ADMIN_LOGIN} element={<Routers.ADMIN_LOGIN />} />
                    <Route path={OurRoutes.DELETE_USER} element={<Routers.DELETE_USER />} />

                    <Route element={<Routers.AdminLayout />}>
                        <Route path={OurRoutes.USERS} element={<Routers.USERS />} />
                        <Route path={OurRoutes.DASHBOARD} element={<Routers.DASHBOARD />} />
                        <Route path={OurRoutes.ADD_QUESTIONS} element={<Routers.ADD_QUESTIONS />} />
                        <Route path={OurRoutes.ALL_QUESTIONS} element={<Routers.ALL_QUESTIONS />} />
                        <Route path={OurRoutes.CHAT} element={<Routers.CHAT />} />
                        <Route path={OurRoutes.ANNOUNCEMENTS} element={<Routers.ANNOUNCEMENTS />} />
                        <Route path={OurRoutes.ADD_SURVEY} element={<Routers.ADD_SURVEY />} />
                        <Route path={OurRoutes.ADD_NOTIFICATIONS} element={<Routers.ADD_NOTIFICATIONS />} />
                        <Route path={OurRoutes.ALL_TRANSACTION} element={<Routers.ALL_TRANSACTION />} />

                    </Route>
                </Routes>
            </Suspense>
        </Router>
    )
}
export default AppRouter;
