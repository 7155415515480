const routes = {
    ADMIN_LOGIN: '/admin/login',
    USERS: '/admin/users',
    DASHBOARD: '/admin/dashboard',
    ADD_QUESTIONS:'/admin/addquestions',
    ALL_QUESTIONS:'/admin/allquestions',
    CHAT:'/admin/chat',
    ANNOUNCEMENTS:'/admin/announcements',
    ADD_SURVEY:'/admin/survey',
    ADD_NOTIFICATIONS:'/admin/notifications',
    ALL_TRANSACTION:'/admin/alltransaction',
    DELETE_USER:'/deactivate'

}

export default routes;